<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>在场车辆</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >车牌号码：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入车牌号码"
            v-model="query.plateNo"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="inTimeBegin"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="inTimeEnd"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="pkInCarsListcx"
          >查询</el-button
        >
        <el-button size="small" @click="pkInCarsListcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="danger"
        size="small "
        icon="el-icon-delete"
        @click="delPkInCarss"
        >删除选择记录</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inTime"
            label="进场时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.inTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="doorName"
            label="入场通道"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="regionName"
            label="所在停车区域"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inPicPath"
            label="进场照片"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.inPicPath"
                :preview-src-list="inimgList"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  pkInCarsList,
  findPkInfoSelect,
  delPkInCars,
} from '@/api/Parkinglot.js'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      tableData: [],
      inTimeBegin: undefined, // 进场时间起始
      inTimeEnd: undefined, // 进场时间截至
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: '', // 停车场id
        plateNo: undefined, // 车牌号
        inTimeBegin: undefined, // 进场时间起始
        inTimeEnd: undefined, // 进场时间截至
      },
      delId: undefined,
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      inimgList: [],
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    pkInCarsList() {
      // 进出记录查询列表
      this.query.inTimeBegin = this.inTimeBegin
      this.query.inTimeEnd = this.inTimeEnd
      if (this.query.inTimeBegin && !this.query.inTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTimeBegin && this.query.inTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTimeBegin || this.query.inTimeEnd) {
        this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0)
        this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0)
      }
      pkInCarsList(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.tableData = res.data.data.list
        this.inimgList = res.data.data.list.map((item) => item.inPicPath)

        this.total = res.data.data.total
        if (this.tableData) {
          this.isShowData = true
          this.loading = false
        }
      })
    },
    pkInCarsListcx() {
      // 查询
      this.query.current = 1
      this.pkInCarsList()
    },
    pkInCarsListcz() {
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.current = 1 
      this.query.size = 10 
      this.query.plateNo = undefined // 车牌号
      this.inTimeBegin = undefined // 进场时间起始
      this.inTimeEnd = undefined // 进场时间截至
      this.pkInCarsList()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.pkInCarsList()
        }
      })
    },
    handleSelectionChange(val) {
      this.delId = ''
      val.forEach((item) => {
        this.delId += item.id + '_'
      })
      this.delId = this.delId.slice(0, this.delId.length - 1)
    },
    delPkInCarss() {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delPkInCars(this.delId, { pk_id: this.query.pk_id }).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              window.location.reload()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.pkInCarsList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.pkInCarsList()
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.inTimeBegin === null || this.inTimeBegin === undefined) {
        this.inTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.inTimeEnd < this.inTimeBegin) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-image-viewer__close {
  color: #333;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
  opacity: 0;
  background-color: #333;
}
::v-deep .el-image-viewer__canvas {
  height: 80%;
  padding-top: 100px;
  box-sizing: border-box;
}
::v-deep .el-image-viewer__wrapper {
  top: 50px;
  left: 20%;
  right: 20%;
  bottom: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 20px 1px #333;
}
</style>
